












































































































import Vue from 'vue'
import {
  Maybe,
  Product,
  ProductInput,
  VariantTypeEnum,
  PriceInput,
  CurrencyEnum,
  Variant,
  VariantInput,
  Tax,
  TaxCollectionEnum,
} from '../../../../shared/types/types'
import {
  MessageInfo,
  MessageTypeEnum,
  VariantInputWithAudit,
} from '../../types'
import ProductTitle from '../../components/products/ProductTitle.vue'
import AddProductImage from '../../components/products/AddProductImage.vue'
import NavigateBack from '../../components/shared/NavigateBack.vue'
import Shipping from '../../components/products/Shipping.vue'
import Inventory from '../../components/products/Inventory.vue'
import Pricing from '../../components/products/Pricing.vue'
import {
  deleteProduct,
  getProduct,
  createProduct,
  updateProduct,
  createVariant,
} from '../../lib/product'
import DuplicateProduct from '../../components/products/DuplicateProduct.vue'
import VariantCard from '../../components/products/VariantCard.vue'
import Message from '../../components/shared/Message.vue'
import { DEFAULT_OPTION_LABEL, DEFAULT_VARIANT_TITLE } from '../../constants/'
import Loader from '../../components/shared/Loader.vue'
import { validateProduct } from '../../lib/validation/product'
import { errorMessage, SuccessMessage } from '../../constants/message'
import {
  getTaxes,
  createExcludeProductVariant,
  deleteExcludeProductVariant,
  getTaxesForProductVariant,
} from '../../lib/tax'

export default Vue.extend({
  name: 'ProductDetails',
  components: {
    AddProductImage,
    NavigateBack,
    ProductTitle,
    Shipping,
    Inventory,
    Pricing,
    VariantCard,
    Loader,
    Message,
  },
  data: () => {
    return {
      loading: false,
      isSaving: false,
      product: {} as Maybe<Product>,
      productInput: {} as ProductInput,
      productHasDefaultVariant: true,
      sku: '',
      trackQuantity: false,
      isOversellable: false,
      quantity: 0,
      variantType: VariantTypeEnum.Physical as VariantTypeEnum,
      price: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      discountedPrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      internalPrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      upsellPrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      discountedUpsellPrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      internalUpsellPrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      packagePrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      discountedPackagePrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      internalPackagePrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      comparePrice: {
        amount: 0,
        currency: CurrencyEnum.Usd,
      } as PriceInput,
      chargeTax: false,
      disableAutomaticDiscount: false,
      hasVariant: false,
      optionLabels: [] as string[],
      inEditMode: false,
      newVariant: [] as VariantInput[],
      productId: '',
      message: {} as MessageInfo,
      taxes: [] as Tax[],
      defaultActiveTaxIds: [] as string[],
      excludedGlobalTaxes: [] as string[],
    }
  },
  computed: {
    getTitle(): string | undefined | null {
      return this.inEditMode ? this.product?.title : 'Create Product'
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.productHasDefaultVariant = false
      this.loading = true
      try {
        this.product = await getProduct(this.$route.params.id)
        this.loading = false
        this.inEditMode = true
        this.variantType = this.product?.variants[0].variantType
          ? this.product?.variants[0].variantType
          : VariantTypeEnum.Physical
      } catch (e) {
        this.loading = false
        this.$buefy.toast.open({
          message: errorMessage.GENERIC_ERR_MSG,
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
    }
    this.setTaxes()
    this.initializeProductInput()
  },
  methods: {
    /**
     * Method to initialize product input
     */
    initializeProductInput() {
      this.productInput = {
        title: this.product?.title || '',
        description: this.product?.description || '',
        optionLabels: this.product?.optionLabels || [],
        imageUrl: this.product?.imageUrl || '',
        variants: [],
      }
      this.productId = this.product?.productId || ''
      // check for default variant
      // the product might have default variant only if the length is 1
      if (
        this.product?.variants?.length === 1 &&
        this.product?.optionLabels?.length === 1
      ) {
        // if variant option label is default option label and title is same as product title
        const variant = this.product?.variants[0]
        const optionLabel = this.product?.optionLabels[0]
        if (
          variant?.option1 === DEFAULT_VARIANT_TITLE &&
          optionLabel === DEFAULT_OPTION_LABEL
        ) {
          // default variant populate other fields
          this.productInput.variants = []
          this.productHasDefaultVariant = true
          this.price.amount = variant?.price?.amount || 0
          this.price.currency = variant?.price?.currency || CurrencyEnum.Usd
          this.discountedPrice = {
            amount: variant?.discountedPrice?.amount || 0,
            currency: variant?.discountedPrice?.currency || CurrencyEnum.Usd,
          }
          this.internalPrice = {
            amount: variant?.internalPrice?.amount || 0,
            currency: variant?.internalPrice?.currency || CurrencyEnum.Usd,
          }
          this.upsellPrice = {
            amount: variant?.upsellPrice?.amount || 0,
            currency: variant?.upsellPrice?.currency || CurrencyEnum.Usd,
          }
          this.discountedUpsellPrice = {
            amount: variant?.discountedUpsellPrice?.amount || 0,
            currency:
              variant?.discountedUpsellPrice?.currency || CurrencyEnum.Usd,
          }
          this.internalUpsellPrice = {
            amount: variant?.internalUpsellPrice?.amount || 0,
            currency:
              variant?.internalUpsellPrice?.currency || CurrencyEnum.Usd,
          }
          this.packagePrice = {
            amount: variant?.packagePrice?.amount || 0,
            currency: variant?.packagePrice?.currency || CurrencyEnum.Usd,
          }
          this.discountedPackagePrice = {
            amount: variant?.discountedPackagePrice?.amount || 0,
            currency:
              variant?.discountedPackagePrice?.currency || CurrencyEnum.Usd,
          }
          this.internalPackagePrice = {
            amount: variant?.internalPackagePrice?.amount || 0,
            currency:
              variant?.internalPackagePrice?.currency || CurrencyEnum.Usd,
          }
          this.comparePrice.amount = variant?.comparePrice?.amount || 0
          this.comparePrice.currency =
            variant?.comparePrice?.currency || CurrencyEnum.Usd
          this.sku = variant?.sku || ''
          this.chargeTax = variant?.chargeTax || false
          this.disableAutomaticDiscount =
            variant?.disableAutomaticDiscount || false
          this.trackQuantity = variant?.trackQuantity || false
          this.quantity = variant?.quantity || 0
          this.isOversellable = variant?.isOversellable || false
        }
      }
      if (!this.productHasDefaultVariant) {
        this.product?.variants?.forEach((variant: Maybe<Variant>) => {
          this.productInput.variants.push({
            title: variant?.title || '',
            option1: variant?.option1 || '',
            option2: variant?.option2 || undefined,
            option3: variant?.option3 || undefined,
            chargeTax: variant?.chargeTax || false,
            disableAutomaticDiscount:
              variant?.disableAutomaticDiscount || false,
            isOversellable: variant?.isOversellable || false,
            imageUrl: variant?.imageUrl || '',
            price: {
              amount: variant?.price?.amount || 0,
              currency: variant?.price?.currency || CurrencyEnum.Usd,
            },
            discountedPrice: {
              amount: variant?.discountedPrice?.amount || 0,
              currency: variant?.discountedPrice?.currency || CurrencyEnum.Usd,
            },
            internalPrice: {
              amount: variant?.internalPrice?.amount || 0,
              currency: variant?.internalPrice?.currency || CurrencyEnum.Usd,
            },
            upsellPrice: {
              amount: variant?.upsellPrice?.amount || 0,
              currency: variant?.upsellPrice?.currency || CurrencyEnum.Usd,
            },
            discountedUpsellPrice: {
              amount: variant?.discountedUpsellPrice?.amount || 0,
              currency:
                variant?.discountedUpsellPrice?.currency || CurrencyEnum.Usd,
            },
            internalUpsellPrice: {
              amount: variant?.internalUpsellPrice?.amount || 0,
              currency:
                variant?.internalUpsellPrice?.currency || CurrencyEnum.Usd,
            },
            packagePrice: {
              amount: variant?.packagePrice?.amount || 0,
              currency: variant?.packagePrice?.currency || CurrencyEnum.Usd,
            },
            discountedPackagePrice: {
              amount: variant?.discountedPackagePrice?.amount || 0,
              currency:
                variant?.discountedPackagePrice?.currency || CurrencyEnum.Usd,
            },
            internalPackagePrice: {
              amount: variant?.internalPackagePrice?.amount || 0,
              currency:
                variant?.internalPackagePrice?.currency || CurrencyEnum.Usd,
            },
            trackQuantity: variant?.trackQuantity || false,
            quantity: variant?.quantity || 0,
            sku: variant?.sku || '',
            variantType: variant?.variantType || VariantTypeEnum.Physical,
            variantId: variant?.variantId || '',
            index: variant?.index || 0,
          })
        })
        this.product?.optionLabels?.forEach((optionLabel: string | null) => {
          if (optionLabel !== null) {
            this.optionLabels.push(optionLabel)
          }
        })
      }
    },
    /**
     * Method to open the duplicate modal
     */
    duplicateProductModal() {
      this.$buefy.modal.open({
        parent: this,
        component: DuplicateProduct,
        hasModalCard: true,
        trapFocus: true,
        props: {
          product: this.product,
        },
        events: {
          productCreated: (productId: string) => {
            this.$router.push(`/products/${productId}`)
          },
        },
      })
    },
    /**
     * Sets variants returned from variant card component
     *
     * @param array of VariantInputWithAudit
     */
    setVariants(variantInput: [VariantInputWithAudit]) {
      this.productInput.variants = []
      variantInput.forEach((variantMap: VariantInputWithAudit) => {
        if (!variantMap.isDeleted) {
          this.productInput.variants.push(variantMap.variant)
        }
      })
    },
    /**
     * Sets whether the variants are present or not for the product
     * @param hasVariant a boolean value
     */
    setVariantStatus(hasVariants: boolean) {
      this.hasVariant = hasVariants
    },
    /**
     * Sets whether the options labels for the product
     * @param optionLabels an array of string
     */
    setOptionLabels(optionLabels: Array<string>) {
      this.productInput.optionLabels = optionLabels
    },
    /**
     * Sets taxes, active tax ids, excluded global taxes
     */
    async setTaxes() {
      this.taxes = await getTaxes(TaxCollectionEnum.AllProducts)
      if (this.$route.params.id) {
        if (this.product?.variants.length === 1) {
          const productVariantTaxes = await getTaxesForProductVariant(
            this.product?.productId || '',
            this.product?.variants[0].variantId || ''
          )
          this.excludedGlobalTaxes = productVariantTaxes
            .filter(
              (tax) =>
                tax.collectionType === TaxCollectionEnum.AllProducts &&
                tax.excludedProductVariants.length &&
                tax.excludedProductVariants.find(
                  (productVariant) =>
                    productVariant.productId === this.product?.productId &&
                    productVariant.variantId ===
                      this.product?.variants[0].variantId
                )
            )
            .map((tax) => tax.taxId)
          this.defaultActiveTaxIds = [
            ...this.taxes
              .filter((tax) => !this.excludedGlobalTaxes.includes(tax.taxId))
              .map((tax) => tax.taxId),
          ]
        }
      } else {
        this.defaultActiveTaxIds = [...this.taxes.map((tax) => tax.taxId)]
      }
    },
    /**
     * Updates the title returned from the add product title component
     * @param title - product title
     */
    updateTitle(title: string) {
      this.productInput.title = title
    },
    /**
     * Updates the selected tax Ids in price component
     * @param activeTaxIds - selected tax ids
     */
    updateActiveTaxIds(activeTaxIds: string[]) {
      this.defaultActiveTaxIds = activeTaxIds
    },
    /**
     * Updates the description returned from the add product title component
     * @param description - product description
     */
    updateDescription(description: string) {
      this.productInput.description = description
    },
    /**
     * Method to update the product image
     * @param - image url to be updated
     */
    updateProductImage(imageUrl: string) {
      this.productInput.imageUrl = imageUrl
    },
    /**
     * Updates the price for the product
     * @param price of Priceinput containing amount and currency
     * @param field which is the name of the pricing field
     */
    updatePrice(price: PriceInput, field: string) {
      switch (field) {
        case 'price':
          this.price.amount = price.amount
          break
        case 'discountedPrice':
          this.discountedPrice.amount = price.amount
          break
        case 'internalPrice':
          this.internalPrice.amount = price.amount
          break
        case 'upsellPrice':
          this.upsellPrice.amount = price.amount
          break
        case 'discountedUpsellPrice':
          this.discountedUpsellPrice.amount = price.amount
          break
        case 'internalUpsellPrice':
          this.internalUpsellPrice.amount = price.amount
          break
        case 'packagePrice':
          this.packagePrice.amount = price.amount
          break
        case 'discountedPackagePrice':
          this.discountedPackagePrice.amount = price.amount
          break
        case 'internalPackagePrice':
          this.internalPackagePrice.amount = price.amount
          break
      }
    },
    /**
     * Updates the compare price for the product
     * @param price of Priceinput containing amount and currency
     */
    updateComparePrice(price: PriceInput) {
      this.comparePrice.amount = price.amount
    },
    /**
     * Updates the chargeTax for the product
     * @param chargeTax
     */
    updateChargeTax(chargeTax: boolean) {
      this.chargeTax = chargeTax
    },
    /**
     * Updates the disableAutomaticDiscount for the product
     * @param disableAutomaticDiscount
     */
    updateDisableAutomaticDiscount(disableAutomaticDiscount: boolean) {
      this.disableAutomaticDiscount = disableAutomaticDiscount
    },
    /**
     * Updates the stock keeping unit
     * @param price of Priceinput containing amount and currency
     */
    updateSku(sku: string) {
      this.sku = sku
    },
    /**
     * Updates the tracking for quantity
     * @param trackQuantity a boolean value
     */
    updateTrackQuantity(trackQuantity: boolean) {
      this.trackQuantity = trackQuantity
    },
    /**
     * Updates the whether the product can be sold more than the quantity
     * @param isOversellable a boolean value
     */
    updateIsOversellable(isOversellable: boolean) {
      this.isOversellable = isOversellable
    },
    /**
     * Updates the quantity for the given product
     * @param isOversellable a boolean value
     */
    updateQuantity(quantity: number) {
      this.quantity = quantity
    },
    /**
     * Updates the variant type
     * @param variantType a VariantTypeEnum <physical | virtual>
     */
    updateVariantType(variantType: VariantTypeEnum) {
      this.variantType = variantType
    },
    /**
     * Updates product variant's excluded global taxes
     *  */
    updateExcludedGlobalTaxes(productId: string, variants: Variant[]) {
      return Promise.all(
        variants.map((variant) => {
          return Promise.all(
            this.taxes
              .filter(
                (tax) => tax.collectionType === TaxCollectionEnum.AllProducts
              )
              .map((tax) => {
                if (
                  !this.excludedGlobalTaxes.includes(tax.taxId) &&
                  !this.defaultActiveTaxIds.includes(tax.taxId)
                ) {
                  return createExcludeProductVariant({
                    taxId: tax.taxId,
                    productVariantForm: {
                      productId: productId,
                      variantId: variant.variantId || '',
                    },
                  })
                } else if (
                  this.excludedGlobalTaxes.includes(tax.taxId) &&
                  this.defaultActiveTaxIds.includes(tax.taxId)
                ) {
                  return deleteExcludeProductVariant({
                    taxId: tax.taxId,
                    productId: productId || '',
                    variantId: variant.variantId || '',
                  })
                } else {
                  return {}
                }
              })
          )
        })
      )
    },
    /**
     * Show the confirm custom delete popup
     */
    confirmProductDelete() {
      if (!this.product) return
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.product.title}?`,
        message: `Are you sure you want to delete the product <strong>${this.product.title}</strong>? This can't be undone.`,
        confirmText: 'Delete Product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await deleteProduct(this.$route.params.id)
            this.$buefy.toast.open({
              message: SuccessMessage.DELETE_PRODUCT_SUCCESS_MSG,
              position: 'is-bottom',
              type: 'is-success',
            })
            this.$router.push(`/products`)
          } catch (e) {
            this.$buefy.toast.open({
              message: errorMessage.GENERIC_ERR_MSG,
              position: 'is-bottom',
              type: 'is-danger',
            })
          }
        },
      })
    },
    /**
     * Save the product if it is valid
     *
     * If there are no variants create a default variant with option label
     * as DEFAULT_OPTION_LABEL and "option1" value as Default Title
     *
     * In edit mode if the product is updated successfully, show success toast
     *
     * In create mode (i.e. !inEditMode) if the product is created successfully, route to the view product page
     */
    async saveProduct() {
      this.isSaving = true
      let { variants } = this.productInput
      const validationObj = validateProduct(this.productInput)
      if (validationObj.isValid && variants.length === 0) {
        this.message = {} as MessageInfo
        this.productInput.variants.push({
          title: this.productInput?.title || '',
          option1: DEFAULT_VARIANT_TITLE,
          optionLabels: [DEFAULT_OPTION_LABEL],
          chargeTax: this.chargeTax,
          disableAutomaticDiscount: this.disableAutomaticDiscount,
          isOversellable: this.isOversellable,
          price: {
            amount: this.price.amount,
            currency: CurrencyEnum.Usd,
          },
          discountedPrice: {
            amount: this.discountedPrice.amount,
            currency: CurrencyEnum.Usd,
          },
          internalPrice: {
            amount: this.internalPrice.amount,
            currency: CurrencyEnum.Usd,
          },
          upsellPrice: {
            amount: this.upsellPrice.amount,
            currency: CurrencyEnum.Usd,
          },
          discountedUpsellPrice: {
            amount: this.discountedUpsellPrice.amount,
            currency: CurrencyEnum.Usd,
          },
          internalUpsellPrice: {
            amount: this.internalUpsellPrice.amount,
            currency: CurrencyEnum.Usd,
          },
          packagePrice: {
            amount: this.packagePrice.amount,
            currency: CurrencyEnum.Usd,
          },
          discountedPackagePrice: {
            amount: this.discountedPackagePrice.amount,
            currency: CurrencyEnum.Usd,
          },
          internalPackagePrice: {
            amount: this.internalPackagePrice.amount,
            currency: CurrencyEnum.Usd,
          },
          trackQuantity: this.trackQuantity,
          variantType: this.variantType,
          sku: this.sku,
          quantity: this.quantity,
          index: 0,
          imageUrl: this.productInput?.imageUrl || '',
        })
        this.productInput.optionLabels = [DEFAULT_OPTION_LABEL]
        if (this.inEditMode) {
          this.productInput.variants[0].variantId = this.product?.variants[0].variantId
        }
      }
      try {
        if (!this.inEditMode) {
          if (validationObj.isValid) {
            const result = await createProduct(this.productInput)
            if (result) {
              await this.updateExcludedGlobalTaxes(
                result.productId || '',
                result.variants
              )
            }
            this.$router.push(`/products/${result?.productId}`)
          } else {
            this.message = {
              type: MessageTypeEnum.Error,
              messageList: validationObj.messageList,
              message: `There is ${validationObj.messageList.length} error with this product:`,
            }
          }
        } else {
          //check if the variant is added to the default variant product
          if (this.productId === '') {
            this.$buefy.toast.open({
              message: errorMessage.PRODUCT_UPDATE_FAILED,
              position: 'is-bottom',
              type: 'is-danger',
            })
            return
          }
          if (this.hasVariant && this.productHasDefaultVariant) {
            if (this.productInput.variants[0]) {
              this.productInput.variants[0].variantId = this.product?.variants
                .length
                ? this.product?.variants[0]?.variantId
                : ''
            }
            this.newVariant = this.productInput.variants.splice(
              1,
              this.productInput.variants.length
            )
            // update the default variant to the first variant added by the user
            const result = await updateProduct(
              this.productId,
              this.productInput
            )
            if (result) {
              await this.updateExcludedGlobalTaxes(
                result.productId || '',
                result.variants
              )
            }
            //create other variants added by the user for the product selected
            await Promise.all(
              this.newVariant.map((variant: VariantInput) => {
                createVariant(this.productId, variant)
              })
            )
            this.$buefy.toast.open({
              message: SuccessMessage.UPDATE_PRODUCT_SUCCESS_MSG,
              position: 'is-bottom',
              type: 'is-success',
            })
          } else {
            //default update of the product
            const result = await updateProduct(
              this.productId,
              this.productInput
            )

            if (result) {
              await this.updateExcludedGlobalTaxes(
                result.productId || '',
                result.variants
              )
            }

            this.$buefy.toast.open({
              message: SuccessMessage.UPDATE_PRODUCT_SUCCESS_MSG,
              position: 'is-bottom',
              type: 'is-success',
            })
          }
        }
      } catch (e) {
        this.isSaving = false
        this.$buefy.toast.open({
          message: errorMessage.GENERIC_ERR_MSG,
          position: 'is-bottom',
          type: 'is-danger',
        })
      }
      this.isSaving = false
    },
  },
})
