

































































import Vue, { PropType, VNode } from 'vue'
import Separator from '../shared/Separator.vue'
import Sortable from 'sortablejs'
import {
  PayloadMapper,
  VariantOptionLabelMapper,
  MessageTypeEnum,
} from '@/types'
import VariantImageUploadVue from './VariantImageUpload.vue'
import { errorMessage } from '@/constants/message'
import Message from '../shared/Message.vue'

/**
 * Create a new instance of Sortable when the element
 * is bound or updated, and destroy it when it's unbound.
 * @param el - the target DOM element
 * @param options - options for the sortable instance
 * @param - vnode - the target node
 */
const createSortable = (
  el: HTMLElement,
  options: Sortable.Options,
  vnode: VNode
) => {
  return Sortable.create(el, {
    ...options,
    onEnd: (evt: Sortable.SortableEvent) => {
      const data = vnode.componentInstance?.$data.tags
      const item = data[evt.oldIndex || 0]
      if ((evt?.newIndex || 0) > (evt?.oldIndex || 0)) {
        for (let i = evt?.oldIndex || 0; i < (evt?.newIndex || 0); i++) {
          data[i] = data[i + 1]
        }
      } else {
        for (let i = evt?.oldIndex || 0; i > (evt?.newIndex || 0); i--) {
          data[i] = data[i - 1]
        }
      }
      data[evt?.newIndex || 0] = item
      vnode.componentInstance?.$emit('input', data)
    },
  })
}
/**
 * Add a new instance of Sortable when the element
 * is bound or updated, and destroy it when it's unbound.
 */
const sortable = {
  name: 'sortable',
  bind(el: any, binding: any, vnode: VNode) {
    const container = el.querySelector('.taginput-container')
    container.sortable = createSortable(container, binding.value, vnode)
  },
  update(el: any, binding: any, vnode: any) {
    const container = el.querySelector('.taginput-container')
    container?._sortable?.destroy()
    container._sortable = createSortable(container, binding.value, vnode)
  },
  unbind(el: any) {
    const container = el.querySelector('.taginput-container')
    container?._sortable?.destroy()
  },
}

export default Vue.extend({
  name: 'ReorderVariants',
  components: { Separator, Message },
  directives: { sortable },
  props: {
    defaultOptions: {
      type: Array as PropType<VariantOptionLabelMapper[]>,
      default: () => [],
    },
  },
  data() {
    return {
      sortableOptions: {
        chosenClass: 'is-primary',
        draggable: '.tag',
      },
      deleteVariants: false,
      draggingRow: {} as VariantOptionLabelMapper,
      draggingRowIndex: 0,
      variantOptions: this.defaultOptions,
      columns: [
        {
          field: 'optionLabel',
          label: '',
        },
        {
          field: 'optionValues',
          label: '',
        },
      ],
      errorMessage: {},
    }
  },
  methods: {
    /**
     * Handler for drag start
     * @param - payload - contains the row information that is dragged over
     */
    dragstart(payload: PayloadMapper) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      if (payload.event.dataTransfer) {
        payload.event.dataTransfer.effectAllowed = 'copy'
      }
    },
    /**
     * Handler for drag over
     * @param - payload - contains the row information that is dragged over
     */
    dragover(payload: PayloadMapper) {
      if (payload.event.dataTransfer) {
        payload.event.dataTransfer.dropEffect = 'copy'
      }
      payload.event.preventDefault()
    },
    /**
     * Handler for drag leave
     * @param - payload - contains the row information that the drag has left
     */
    dragleave(payload: PayloadMapper) {
      payload.event.preventDefault()
    },
    /**
     * Handler for drop
     * @param - payload - contains the row information that has been dropped
     */
    drop(payload: PayloadMapper) {
      const droppedOnRowIndex = payload.index
      const newData: VariantOptionLabelMapper[] = [...this.variantOptions]
      let temp: VariantOptionLabelMapper = newData[this.draggingRowIndex]
      temp.index = droppedOnRowIndex
      newData[this.draggingRowIndex] = newData[droppedOnRowIndex]
      newData[this.draggingRowIndex].index = this.draggingRowIndex
      newData[droppedOnRowIndex] = temp
      this.variantOptions = newData
    },
    /**
     * Handler for saving the data
     */
    handleSave() {
      if (this.variantOptions[0].optionValues.length) {
        this.$emit('update-options', this.variantOptions)
        this.$emit('close')
      } else {
        this.errorMessage = {
          type: MessageTypeEnum.Error,
          messageList: [errorMessage.VARIANT_OPTIONS_EMPTY],
          message: `There is error with this reordering variants:`,
        }
      }
    },
  },
})
