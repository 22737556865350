var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4"},[(_vm.inEditMode)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"min-w-max font-bold"},[_vm._v("Select :")]),_c('ul',{staticClass:"flex cursor-pointer p-0 ml-5 has-text-info"},[_c('li',{staticClass:"ml-1 mr-1 has-text-info",on:{"click":function($event){return _vm.selectAllVariants()}}},[_vm._v(" All ")]),_c('li',{staticClass:"ml-1 mr-1 has-text-info",on:{"click":function($event){return _vm.unSelectVariants()}}},[_vm._v(" None ")]),_vm._l((_vm.allOptions),function(option,index){return _c('li',{key:index,staticClass:"mx-2 has-text-info",on:{"click":function($event){return _vm.selectVariants(option)}}},[_vm._v(" "+_vm._s(option)+" ")])})],2)]):_vm._e(),_c('separator'),(_vm.selectedVariants.length)?_c('div',{staticClass:"flex items-center bg-gray-100 p-2 rounded-md border-solid border-gray-200 border-1"},[_c('div',{staticClass:"text-sm font-semibold mr-2"},[_vm._v(" "+_vm._s(_vm.selectedVariants.length)+" selected ")]),_c('b-dropdown',{staticClass:"ml-auto",attrs:{"position":"is-bottom-left"}},[_c('b-button',{attrs:{"slot":"trigger","icon-right":"menu-down"},slot:"trigger"},[_vm._v(" Actions ")]),_c('b-dropdown-item',{on:{"click":_vm.deleteSelectedVariants}},[_vm._v(" Delete variants ")]),_c('b-dropdown-item',{on:{"click":_vm.openEditTaxesModal}},[_vm._v(" Edit Taxes ")])],1)],1):_vm._e(),_c('b-table',{attrs:{"data":_vm.variants,"checked-rows":_vm.selectedVariants,"checkable":_vm.inEditMode,"scrollable":"","sticky-checkbox":""},on:{"update:checkedRows":function($event){_vm.selectedVariants=$event},"update:checked-rows":function($event){_vm.selectedVariants=$event}}},[_c('b-table-column',{attrs:{"visible":_vm.inEditMode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.variant.imageUrl)?_c('item-image',{attrs:{"image-url":row.variant.imageUrl,"size":_vm.ImageSizeEnum.Large}}):_c('div',{staticClass:"border rounded p-3 flex justify-center items-center w-min"},[_c('label',{staticClass:"flex items-center",attrs:{"for":"add-variant-image"},on:{"click":function($event){return _vm.openImageUploadModal(row.variant)}}},[_c('b-icon',{staticClass:"text-gray-500",attrs:{"icon":"image","size":"is-medium","custom-size":"mdi-36px"}})],1)])]}}])}),_c('b-table-column',{attrs:{"label":"Variant"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.variant.title)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.price.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'price')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Discounted price","cell-class":"min-w-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.discountedPrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'discountedPrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Internal price (hidden)","cell-class":"min-w-full"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.internalPrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'internalPrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Upsell price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.upsellPrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'upsellPrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Discounted upsell price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.discountedUpsellPrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'discountedUpsellPrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Internal upsell price (hidden)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.internalUpsellPrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'internalUpsellPrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Package price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.packagePrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'packagePrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Discounted package price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.discountedPackagePrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'discountedPackagePrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Internal package price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('currency-input',{attrs:{"value":row.variant.internalPackagePrice.amount},on:{"input":function($event){return _vm.editVariant(row, $event, 'internalPackagePrice')}}})]}}])}),_c('b-table-column',{attrs:{"label":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-input',{staticClass:"w-24",attrs:{"type":"number","min":"0","value":row.variant.quantity},on:{"input":function($event){return _vm.editVariant(row)}},model:{value:(row.variant.quantity),callback:function ($$v) {_vm.$set(row.variant, "quantity", $$v)},expression:"row.variant.quantity"}})]}}])}),_c('b-table-column',{attrs:{"label":"SKU"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-input',{staticClass:"w-32",attrs:{"value":row.variant.sku},on:{"input":function($event){return _vm.editVariant(row)}},model:{value:(row.variant.sku),callback:function ($$v) {_vm.$set(row.variant, "sku", $$v)},expression:"row.variant.sku"}})]}}])}),_c('b-table-column',{attrs:{"field":"delete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.inEditMode)?_c('div',{staticClass:"float-right"},[_c('router-link',{staticClass:"text-current",attrs:{"to":("/products/" + _vm.productId + "/variant/" + (row.variant.variantId))}},[_c('b-button',{staticClass:"mr-2"},[_vm._v(" Edit ")])],1),_c('b-button',{on:{"click":function($event){return _vm.deleteVariant(row)}}},[_c('b-icon',{attrs:{"icon":"trash-can-outline","size":"is-small","custom-size":"mdi-18px"}})],1)],1):_vm._e(),(!_vm.inEditMode)?_c('div',[(!row.isDeleted)?_c('b-button',{attrs:{"icon-right":"delete"},on:{"click":function($event){return _vm.deleteVariant(row)}}}):_vm._e(),(row.isDeleted)?_c('div',[_vm._v(" This variant will not be created "),_c('span',{staticClass:"underline text-blue-600 cursor-pointer",on:{"click":function($event){return _vm.undoDeleteVariant(row)}}},[_vm._v(" Undo ")])]):_vm._e()],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }