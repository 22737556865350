
































































import Vue, { PropType } from 'vue'
import Separator from '@/components/shared/Separator.vue'
import {
  Tax,
  TaxCollectionEnum,
  CurrencyEnum,
} from '../../../../shared/types/types'
import { VariantInputWithAudit } from '@/types'
import { formatPrice } from '@/lib/utils'
import {
  createExcludeProductVariant,
  deleteExcludeProductVariant,
  updateTax,
} from '@/lib/tax'

export default Vue.extend({
  name: 'EditTaxes',
  components: {
    Separator,
  },
  props: {
    variantIdToApplicableTaxes: {
      type: Object as PropType<Record<string, Tax[]>>,
      required: true,
    },
    variants: {
      type: Array as PropType<VariantInputWithAudit[]>,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variantIdToActiveTaxIds: {} as Record<string, string[]>,
      fullyActiveTaxIds: [] as string[],
      isLoading: false,
    }
  },
  computed: {
    /**
     * Returns an array of all applicable, unique taxes across all variants
     */
    allTaxes(): Tax[] {
      return Object.values(this.variantIdToApplicableTaxes)
        .flat()
        .filter(
          (tax, index, arr) =>
            arr.findIndex((t) => t.taxId === tax.taxId) === index
        )
    },
  },
  watch: {
    variantIdToActiveTaxIds: {
      handler() {
        this.getAllFullyActiveTaxIds()
      },
      deep: true,
    },
  },
  created() {
    this.getActiveTaxIdsForAllVariants()
    this.getAllFullyActiveTaxIds()
  },
  methods: {
    /**
     * For each variant, get all active tax ids & set them in this.variantIdToActiveTaxIds
     */
    getActiveTaxIdsForAllVariants() {
      for (const variant of this.variants) {
        const variantId = variant.variant.variantId

        if (!variantId) continue

        this.variantIdToActiveTaxIds[
          variantId
        ] = this.getActiveTaxIdsForVariant(variantId)
      }
    },
    /**
     * Get active tax ids for a variant
     *
     * @param variantId - variant id
     */
    getActiveTaxIdsForVariant(variantId: string): string[] {
      const applicableTaxesForVariant = this.variantIdToApplicableTaxes[
        variantId
      ]

      return applicableTaxesForVariant
        .filter((tax) => this.isTaxActiveForVariant(tax, variantId))
        .map((tax) => tax.taxId)
    },
    /**
     * Check if a given tax is active for the variantId
     *
     * @param tax - tax object
     * @param variantId - variant id
     */
    isTaxActiveForVariant(tax: Tax, variantId: string): boolean {
      if (tax.collectionType === TaxCollectionEnum.AllProducts) {
        return !tax.excludedProductVariants.find(
          (excludedProductVariant) =>
            excludedProductVariant.productId === this.productId &&
            excludedProductVariant.variantId === variantId
        )
      }

      if (tax.collectionType === TaxCollectionEnum.SelectedProductVariants) {
        const productVariant = tax.productVariants.find(
          (productVariant) =>
            productVariant.productId === this.productId &&
            productVariant.variantId === variantId
        )

        return productVariant?.isActive || false
      }

      return false
    },
    /**
     * Get all tax ids that are active across all their applicable variants
     */
    getAllFullyActiveTaxIds() {
      this.fullyActiveTaxIds = this.allTaxes
        .filter((tax) => {
          return this.getAllVariantIdsForTax(tax.taxId).every((variantId) =>
            this.variantIdToActiveTaxIds[variantId].includes(tax.taxId)
          )
        })
        .map((tax) => tax.taxId)
    },
    /**
     * Get all variantIds where the taxId is applicable to them
     *
     * @param taxId - tax id
     */
    getAllVariantIdsForTax(taxId: string): string[] {
      return Object.keys(this.variantIdToApplicableTaxes).filter(
        (variantId) => {
          return this.variantIdToApplicableTaxes[variantId].find(
            (variantTax) => variantTax.taxId === taxId
          )
        }
      )
    },
    /**
     * Check if tax is applied only to a subset of applicable variants
     *
     * @param tax - tax object
     */
    isTaxIndeterminate(tax: Tax) {
      const relevantVariants = this.getAllVariantIdsForTax(tax.taxId)
      const variantIdsWithTaxActive = relevantVariants.filter((variantId) =>
        this.variantIdToActiveTaxIds[variantId].includes(tax.taxId)
      )

      return !!(
        variantIdsWithTaxActive.length &&
        variantIdsWithTaxActive.length !== relevantVariants.length
      )
    },
    /**
     * Enable/disable the tax on all applicable variants
     *
     * @param tax - tax object
     * @param isActive - tax status
     */
    toggleTaxStatusAcrossAllApplicableVariants(tax: Tax, isActive: boolean) {
      const relevantVariants = this.getAllVariantIdsForTax(tax.taxId)

      relevantVariants.forEach((variantId) => {
        // Filter out the taxId from the array, so we don't accidentely have it twice.
        // Or if the tax should be removed, it's done here.
        let newActiveTaxIds = this.variantIdToActiveTaxIds[variantId].slice()
        newActiveTaxIds = newActiveTaxIds.filter((taxId) => taxId !== tax.taxId)

        if (isActive) {
          // Just re-add the tax is needed
          newActiveTaxIds.push(tax.taxId)
        }

        this.variantIdToActiveTaxIds = {
          ...this.variantIdToActiveTaxIds,
          [variantId]: newActiveTaxIds,
        }
      })
    },
    /**
     * Get the string represenation of the tax
     *
     * @param tax - tax object
     */
    getTaxValue(tax: Tax): string {
      return tax.flatRate ? formatPrice(tax.flatRate) : `${tax.percentage}%`
    },
    /**
     * Return applicable taxes for a variant id
     *
     * @param variantId - variant id
     */
    getTaxesForVariant(variantId: string): Tax[] {
      return this.variantIdToApplicableTaxes[variantId]
    },
    /**
     * Determine whether the separator should show
     *
     * @param itemIndex - element's index
     * @param arrayLength - length of the array
     */
    shouldShowSeparator(itemIndex: number, arrayLength: number) {
      return itemIndex + 1 !== arrayLength
    },
    /**
     * Get newly enabled & disbaled taxes for a product variant
     *
     * @param variantId - variant id
     */
    getModifiedTaxesForVariant(variantId: string): Tax[][] {
      const originalActiveTaxIds = this.getActiveTaxIdsForVariant(variantId)
      const currentActiveTaxIds = this.variantIdToActiveTaxIds[variantId]

      const newlyEnabledTaxes = currentActiveTaxIds
        .filter((taxId) => !originalActiveTaxIds.includes(taxId))
        .map(this.getTaxById)
        .filter(Boolean)

      const newlyDisabledTaxes = originalActiveTaxIds
        .filter((taxId) => !currentActiveTaxIds.includes(taxId))
        .map(this.getTaxById)
        .filter(Boolean)

      return [newlyEnabledTaxes as Tax[], newlyDisabledTaxes as Tax[]]
    },
    /**
     * Get tax by id from this.allTaxes array
     *
     * @param taxId - tax id
     */
    getTaxById(taxId: string): Tax | undefined {
      return this.allTaxes.find((tax) => tax.taxId === taxId)
    },
    /**
     * Check if given tax is a global tax
     *
     * @param tax - tax object
     */
    isGlobalTax(tax: Tax): boolean {
      return tax.collectionType === TaxCollectionEnum.AllProducts
    },
    /**
     * Check if given tax is a collection tax
     *
     * @param tax - tax object
     */
    isCollectionTax(tax: Tax): boolean {
      return tax.collectionType === TaxCollectionEnum.SelectedProductVariants
    },
    /**
     * Save taxes
     */
    async handleSave() {
      this.isLoading = true

      let taxPromises: Promise<any>[] = []

      for (const variant of this.variants) {
        const variantId = variant.variant.variantId

        if (!variantId) continue

        const [enabledTaxes, disabledTaxes] = this.getModifiedTaxesForVariant(
          variantId
        )

        enabledTaxes.forEach((tax) => {
          if (this.isGlobalTax(tax)) {
            taxPromises.push(
              deleteExcludeProductVariant({
                taxId: tax.taxId,
                productId: this.productId,
                variantId,
              })
            )
          }

          if (this.isCollectionTax(tax)) {
            taxPromises.push(
              updateTax({
                taxId: tax.taxId,
                title: tax?.title,
                type: tax?.type,
                collectionType: tax?.collectionType,
                currency: CurrencyEnum.Usd,
                productVariants: [
                  {
                    productId: this.productId,
                    variantId,
                    isActive: true,
                  },
                ],
              })
            )
          }
        })

        disabledTaxes.forEach((tax) => {
          if (this.isGlobalTax(tax)) {
            taxPromises.push(
              createExcludeProductVariant({
                taxId: tax.taxId,
                productVariantForm: {
                  productId: this.productId,
                  variantId,
                },
              })
            )
          }

          if (this.isCollectionTax(tax)) {
            taxPromises.push(
              updateTax({
                taxId: tax.taxId,
                title: tax?.title,
                type: tax?.type,
                collectionType: tax?.collectionType,
                currency: CurrencyEnum.Usd,
                productVariants: [
                  {
                    productId: this.productId,
                    variantId,
                    isActive: false,
                  },
                ],
              })
            )
          }
        })
      }

      await Promise.all(taxPromises)

      this.isLoading = false

      this.$emit('close')
    },
    /**
     * Force update
     *
     * This is needed because we have a v-model on an object key & Vue doesn't
     * work well with nested object schenanigans
     */
    forceUpdate() {
      this.$forceUpdate()
    },
  },
})
