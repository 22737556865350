
























import { validateTitle } from '@/lib/validation/product'
import Vue from 'vue'
import { ErrorInfo, ErrorObject } from '../../types'

export default Vue.extend({
  name: 'ProductTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    submitted: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      productTitle: this.title,
      productDescription: this.description,
      errorInfo: {} as ErrorInfo,
      error: {
        title: { isValid: true, messageList: [''] } as ErrorObject,
      },
    }
  },

  methods: {
    /**
     * Handles title change
     */
    handleTitleChange() {
      this.error.title = validateTitle(this.productTitle)
      this.$emit('update-title', this.productTitle)
    },
    /**
     * Handles decription change
     */
    handleDescriptionChange() {
      this.$emit('update-description', this.productDescription)
    },
    /**
     * Verify with the field has error message
     * 
     * @param - data - returned as error object after validation
     */
    getErrorType(data: ErrorObject): string {
      return data.isValid ? '' : 'is-danger'
    },
    /**
     * Get the error message for the field
     * 
     * @param - data - returned as error object after validation
     */
    getErrorMessage(data: ErrorObject): string {
      return data.isValid ? '' : data.messageList[0] || ''
    },
  },
})
