



































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import { VariantOptionLabelMapper } from '../../types'
import { OPTION_LABELS, MAX_OPTIONS } from '../../constants/'

export default Vue.extend({
  name: 'VariantOptionLabelsSelect',
  components: { Separator },
  props: {
    index: {
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    variantOptionMap: {
      type: Object as PropType<VariantOptionLabelMapper>,
      required: true,
    },
  },
  data() {
    return {
      variantMap: {
        index: this.variantOptionMap.index,
        optionLabel: this.variantOptionMap.optionLabel,
        optionValues: this.variantOptionMap.optionValues,
      } as VariantOptionLabelMapper,
      optionLabelsToShow: OPTION_LABELS,
    }
  },
  computed: {
    /**
     * Returns option title by combining the index
     */
    getOptionTitle(): string {
      return `Option ${this.index + 1}`
    },
    /**
     * Determines whether to show the bottom separator
     * Not showing for the last option
     */
    showSeparator(): boolean {
      return this.index !== MAX_OPTIONS - 1
    },
    /**
     * To show remove button only if total options length is greater than 1
     */
    showRemoveButton(): boolean {
      return this.length !== 1
    },
  },
  methods: {
    /**
     * Handles the select of option label
     * @param - selecedOptionLabel of type string
     */
    selectOptionLabel(selectedOptionLabel: string) {
      this.variantMap.optionLabel = selectedOptionLabel
      this.$emit('option-change', this.variantMap)
    },
    /**
     * Handles the remove option button click
     */
    removeOption() {
      this.$emit('remove-option', this.index)
    },
    /**
     * Handles the tag input change
     */
    handleTagInput() {
      this.$emit('option-change', this.variantMap)
    },
  },
})
