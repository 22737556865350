




























































































import Vue, { PropType } from 'vue'
import Separator from '../shared/Separator.vue'
import { MAX_OPTIONS } from '../../constants/'
import { VariantOptionLabelMapper, OptionsMapper } from '../../types'

export default Vue.extend({
  name: 'EditOptions',
  components: { Separator },
  props: {
    defaultOptionLabels: {
      type: Array as PropType<VariantOptionLabelMapper[]>,
      default: () => [],
    },
  },
  data() {
    return {
      newOptionLabels: [] as VariantOptionLabelMapper[],
      deletedOptions: [] as OptionsMapper[],
      confirmVariantDelete: false,
      addAnotherOption: false,
      optionLabels: [] as VariantOptionLabelMapper[],
    }
  },
  computed: {
    canAddOptions(): boolean {
      return (
        this.optionLabels.length + this.newOptionLabels.length < MAX_OPTIONS
      )
    },
    canShowNewOptionButton(): boolean {
      return this.optionLabels.length < MAX_OPTIONS
    },
  },
  created() {
    this.optionLabels = this.defaultOptionLabels
  },
  methods: {
    /**
     * Method to check whether a tag can be closed
     *
     * @param optionValues - option array
     */
    canCloseTag(optionValues: OptionsMapper[]): boolean {
      return optionValues.length > 1
    },
    /**
     * Method to see whether delete button can be shown on the option
     *
     * @param optionValues - array of options
     */
    canShowDeleteButton(optionValues: string[]) {
      return optionValues.length === 1 && this.optionLabels.length > 1
    },
    /**
     * Method to delete a variant when any option/option value is deleted
     */
    handleDeleteVariant() {
      this.$emit('delete-options', this.optionLabels)
      this.$emit('close')
    },
    /**
     * Method to save the options
     */
    handleSaveOptions() {
      let newOptionLabels = [...this.optionLabels]
      // if the newOptionLabel's optionValue is not empty
      if (
        this.newOptionLabels.length > 0 &&
        this.newOptionLabels[0].optionLabel.length
      ) {
        this.newOptionLabels.forEach((option: VariantOptionLabelMapper) => {
          newOptionLabels.push(option)
        })
        this.newOptionLabels = []
      }
      if (this.deletedOptions.length === 0) {
        this.$emit('update-options', newOptionLabels)
        this.$emit('close')
      } else {
        this.confirmVariantDelete = true
        this.optionLabels = newOptionLabels
      }
    },
    /**
     * Method to remove an option value
     *
     * @param index - index of the optionLabel
     * @param optionValueIndex - index of the option value
     */
    removeOptionValue(index: number, optionValueIndex: number) {
      let key = this.optionLabels[index].optionLabel
      let value = this.optionLabels[index].optionValues[optionValueIndex]
      this.deletedOptions.push({ key, value })
      if (this.optionLabels[index].optionValues.length === 1) {
        this.optionLabels.splice(index)
      } else {
        this.optionLabels[index].optionValues.splice(optionValueIndex, 1)
      }
    },
    /**
     * Method to delete an option
     *
     * @param optionIndex - index of the option
     */
    deleteOption(optionIndex: number) {
      let newOptionLabels = this.optionLabels.filter(
        (option, index) => optionIndex != index
      )
      if (newOptionLabels.length > 0) this.optionLabels = newOptionLabels
    },
    /**
     * Method to handle deleting a new added option
     *
     * @param - index - index of that option
     */
    deleteNewOption(index: number) {
      this.newOptionLabels.splice(index, 1)
    },

    /**
     * Method to add a new option
     */
    addNewOption() {
      if (this.optionLabels.length + this.newOptionLabels.length >= MAX_OPTIONS)
        return
      this.newOptionLabels.push({
        index: this.optionLabels.length,
        optionLabel: '',
        optionValues: [],
      })
    },
  },
})
