






























































































































































































import { validatePrice, validateQuantity } from '@/lib/validation/product'
import Vue, { PropType } from 'vue'
import {
  CurrencyEnum,
  Price,
  Tax,
  VariantInput,
} from '../../../../shared/types/types'
import { ErrorObject, VariantInputWithAudit } from '../../types'
import Separator from '../shared/Separator.vue'
import CurrencyInput from '../shared/CurrencyInput.vue'
import VariantImageUpload from './VariantImageUpload.vue'
import ItemImage from '../shared/ItemImage.vue'
import { ImageSizeEnum } from '../../types'
import EditTaxes from './EditTaxes.vue'
import { getTaxesForProductVariant } from '@/lib/tax'

export default Vue.extend({
  name: 'VariantPreview',
  components: {
    Separator,
    ItemImage,
    CurrencyInput,
  },
  props: {
    variants: {
      type: Array as PropType<VariantInputWithAudit[]>,
      default: () => {
        return []
      },
      required: true,
    },
    inEditMode: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedVariants: [] as VariantInputWithAudit[],
      ImageSizeEnum,
      error: {
        price: { isValid: true, messageList: [''] } as ErrorObject,
      },
      taxes: {} as Record<string, Tax[]>,
    }
  },
  computed: {
    /**
     * Method that returns a set of all unique option values
     */
    allOptions(): Set<string> {
      let options = new Set<string>()
      for (let { variant } of this.variants) {
        let { option1, option2, option3 } = variant
        option1 && options.add(option1)
        option2 && options.add(option2)
        option3 && options.add(option3)
      }
      return options
    },
  },
  watch: {
    /**
     * Reset the selected variants when variants are refreshed.
     */
    variants() {
      this.selectedVariants = []
    },
  },
  async created() {
    this.taxes = await this.getTaxesForAllVariants()
  },
  methods: {
    /**
     * Method to return the dropdown icon
     */
    menuIcon(active: boolean) {
      return active ? 'menu-up' : 'menu-down'
    },
    /**
     * method to open the image upload modal
     *
     * @param variant - variant to edit
     */
    openImageUploadModal(variant: VariantInput) {
      this.$buefy.modal.open({
        parent: this,
        component: VariantImageUpload,
        hasModalCard: true,
        trapFocus: true,
        width: 600,
        props: {
          defaultImage: variant.imageUrl,
        },
        events: {
          'update-image': (imageUrl: string) => {
            this.updateVariantImage(imageUrl, variant)
          },
        },
      })
    },
    /**
     * Method to update the variant image
     */
    updateVariantImage(imageUrl: string, variant: VariantInput) {
      variant.imageUrl = imageUrl
    },
    /**
     * Select all the variants present
     */
    selectAllVariants() {
      this.selectedVariants = this.variants
    },
    /**
     * Unselect the all the variant in variant table checkbox
     */
    unSelectVariants() {
      this.selectedVariants = []
    },
    /**
     * Select variants based on filter option selected
     *
     * @param selectedOption - option to select variants
     */
    selectVariants(selectedOption: string) {
      for (let variant of this.variants) {
        const { option1, option2, option3 } = variant.variant
        const currentOptions = [option1, option2, option3]
        if (
          currentOptions.includes(selectedOption) &&
          !this.selectedVariants.includes(variant)
        ) {
          this.selectedVariants.push(variant)
        }
      }
    },
    /**
     * Handles edit variant
     *
     * @param editVariant - VariantInput
     * @param event - Currency value
     * @param field - Variant fields
     */
    editVariant(variant: VariantInputWithAudit, event: number, field: string) {
      switch (field) {
        case 'price':
          variant.variant.price.amount = event
          break
        case 'discountedPrice':
          variant.variant.discountedPrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'internalPrice':
          variant.variant.internalPrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'upsellPrice':
          variant.variant.upsellPrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'discountedUpsellPrice':
          variant.variant.discountedUpsellPrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'internalUpsellPrice':
          variant.variant.internalUpsellPrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'packagePrice':
          variant.variant.packagePrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'discountedPackagePrice':
          variant.variant.discountedPackagePrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
        case 'internalPackagePrice':
          variant.variant.internalPackagePrice = {
            amount: event,
            currency: CurrencyEnum.Usd,
          }
          break
      }
      this.$emit('edit-variant', variant)
    },
    /**
     * Handles delete variant
     *
     * @param deleteVariant - VariantInput
     */
    deleteVariant(deleteVariant: VariantInputWithAudit) {
      deleteVariant.isDeleted = true
      if (!this.inEditMode) {
        this.$emit('edit-variant', deleteVariant)
      } else {
        this.$emit('delete-variant', [deleteVariant])
      }
    },
    /**
     * Handles undo delete variant
     *
     * @param editVariant - VariantInput
     */
    undoDeleteVariant(variant: VariantInputWithAudit) {
      variant.isDeleted = false
      this.$emit('edit-variant', variant)
    },
    /**
     * Get the error type for price
     *
     * @param - price - price that needs to be validated
     */
    getPriceErrorType(price: Price): string {
      let errorObject: ErrorObject = validatePrice(price)
      return errorObject.isValid ? '' : 'is-danger'
    },
    /**
     * Get the error Message for price
     *
     * @param - price - price that needs to be validated
     */
    getPriceErrorMessage(price: Price): string {
      let errorObject: ErrorObject = validatePrice(price)
      return errorObject.isValid ? '' : errorObject.messageList[0] || ''
    },
    /**
     * Get the error type for quantity
     *
     * @param - quantity - quantity that needs to be validated
     */
    getQuantityErrorType(quantity: number): string {
      let errorObject: ErrorObject = validateQuantity(quantity)
      return errorObject.isValid ? '' : 'is-danger'
    },
    /**
     * Get the error message for quantity
     *
     * @param - quantity - quantity that needs to be validated
     */
    getQuantityErrorMessage(quantity: number): string {
      let errorObject: ErrorObject = validateQuantity(quantity)
      return errorObject.isValid ? '' : errorObject.messageList[0] || ''
    },
    /**
     * Method to delete the selected variant/s
     */
    deleteSelectedVariants() {
      this.$emit('delete-variant', [...this.selectedVariants])
    },
    async getTaxesForAllVariants() {
      if (!this.productId) return {}

      const variantIdToTaxesMap = this.variants.reduce((base, variant) => {
        if (variant?.variant.variantId) {
          base[variant.variant.variantId] = []
        }

        return base
      }, {} as Record<string, Tax[]>)

      for (const variant of this.variants) {
        if (!variant.variant.variantId) continue

        const taxes = await getTaxesForProductVariant(
          this.productId,
          variant.variant.variantId
        )

        variantIdToTaxesMap[variant.variant.variantId] = taxes
      }

      return variantIdToTaxesMap
    },
    openEditTaxesModal() {
      const taxes = {} as Record<string, Tax[]>

      this.selectedVariants.forEach((variant) => {
        if (!variant.variant.variantId) return

        taxes[variant.variant.variantId] = this.taxes[variant.variant.variantId]
      })

      this.$buefy.modal.open({
        parent: this,
        component: EditTaxes,
        trapFocus: true,
        props: {
          variantIdToApplicableTaxes: taxes,
          variants: this.selectedVariants,
          productId: this.productId,
        },
        events: {
          close: async () => {
            this.taxes = await this.getTaxesForAllVariants()
          },
        },
      })
    },
  },
})
