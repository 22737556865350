































































import Vue from 'vue'
import {
  AssetPrefixKey,
  MAX_ALLOWED_FILE_SIZE_FOR_PRODUCT_IMAGE,
} from '../../constants/'
import { uploadFileToS3 } from '../../lib/asset'
import Separator from '../shared/Separator.vue'
import ItemImage from '../shared/ItemImage.vue'
import { ImageSizeEnum } from '../../types'

export default Vue.extend({
  name: 'VariantImageUpload',
  components: { Separator, ItemImage },
  props: {
    defaultImage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: {} as File,
      isUploading: false,
      currentImage: '',
      ImageSizeEnum,
    }
  },
  created() {
    this.currentImage = this.defaultImage
  },
  methods: {
    async uploadProductImage() {
      // Converting bytes to megabytes
      const fileSizeInMB = this.file.size / (1024 * 1024)
      if (fileSizeInMB <= MAX_ALLOWED_FILE_SIZE_FOR_PRODUCT_IMAGE) {
        try {
          this.isUploading = true
          this.currentImage = await uploadFileToS3(
            this.file,
            AssetPrefixKey.Inventory
          )
        } catch (error) {
          this.$buefy.toast.open({
            message: 'Failed to upload the image. Please try again later.',
            type: 'is-danger',
            position: 'is-bottom',
          })
        }
        this.isUploading = false
        // Reset the file input
        this.file = {} as File
      } else {
        this.$buefy.toast.open({
          message: 'Image size should be less than 10MB.',
          type: 'is-danger',
          position: 'is-bottom',
        })
      }
    },
    updateVariantImage() {
      this.$emit('update-image', this.currentImage)
      this.$emit('close')
    },
  },
})
