

































































import Vue, { PropType } from 'vue'
import VariantOptionLabelsSelect from './VariantOptionLabelsSelect.vue'
import VariantPreview from './VariantPreview.vue'
import EditOptions from './EditOptions.vue'
import ReorderVariants from './ReorderVariants.vue'
import {
  getCombinations,
  generateVariantTitle,
  getTempVariantsForPreview,
  generateKSUID,
} from '../../lib/utils'
import {
  VariantOptionLabelMapper,
  VariantInputWithAudit,
  ErrorInfo,
} from '../../types'
import {
  VariantTypeEnum,
  CurrencyEnum,
  VariantInput,
} from '../../../../shared/types/types'
import { MAX_OPTIONS } from '../../constants'
import { deleteVariant } from '../../lib/product'
import pluralize from 'pluralize'

export default Vue.extend({
  name: 'VariantCard',
  components: {
    VariantOptionLabelsSelect,
    VariantPreview,
  },
  props: {
    submitted: {
      type: Boolean,
      default: false,
    },
    trackQuantity: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    isOversellable: {
      type: Boolean,
      default: false,
    },
    sku: {
      type: String,
      default: '',
    },
    variantType: {
      type: String as PropType<VariantTypeEnum>,
      default: VariantTypeEnum.Physical,
    },
    chargeTax: {
      type: Boolean,
      default: false,
    },
    disableAutomaticDiscount: {
      type: Boolean,
      default: false,
    },
    variants: {
      type: Array as PropType<VariantInput[]>,
      default: () => [],
    },
    optionLabels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    productId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasVariant: false,
      selectedOptionLabels: [] as string[],
      variantOptionLabelMaps: [] as VariantOptionLabelMapper[],
      variantsForPreview: [] as VariantInputWithAudit[],
      errorInfo: {} as ErrorInfo,
      inEditMode: false,
      isDeleteVariant: false,
      isReorderVariant: false,
      isEditVariant: false,
    }
  },
  computed: {
    showAddOptionButton(): boolean {
      return this.variantOptionLabelMaps.length < MAX_OPTIONS
    },
    showVariantPreview(): boolean {
      return this.variantsForPreview.length > 0
    },
  },
  watch: {
    hasVariant() {
      this.$emit('set-variant-status', this.hasVariant)
    },
    submitted() {
      this.errorInfo = {} as ErrorInfo
    },
  },
  created() {
    if (this.variants?.length > 0) {
      this.hasVariant = true
      this.inEditMode = true
      this.populateVariantInput()
    } else {
      this.variantOptionLabelMaps.push({
        id: generateKSUID(),
        index: 0,
        optionLabel: '',
        optionValues: [],
      } as VariantOptionLabelMapper)
    }
  },
  methods: {
    /**
     * handler for opening EditOptions modal
     */
    editOptions() {
      this.$buefy.modal.open({
        parent: this,
        component: EditOptions,
        hasModalCard: true,
        trapFocus: true,
        props: {
          defaultOptionLabels: this.variantOptionLabelMaps,
        },
        events: {
          'update-options': this.updateOptionLabels,
          'delete-options': this.deleteOptionLabels,
        },
      })
    },
    /**
     * Method to handle reorder variants
     *
     *@param data - variant option labels array
     */
    handleReorderVariants(data: VariantOptionLabelMapper[]) {
      this.isReorderVariant = true
      this.variantOptionLabelMaps = data
      this.handleVariantsForPreview()
    },
    /**
     * Handler for update option labels
     *
     * @param - data - option label map returned from edit options
     */
    deleteOptionLabels(data: VariantOptionLabelMapper[]) {
      this.variantOptionLabelMaps = data
      this.isDeleteVariant = true
      this.handleVariantsForPreview()
    },
    /**
     * Handler for update option labels
     *
     * @param - data - option label map returned from edit options
     */
    updateOptionLabels(data: VariantOptionLabelMapper[]) {
      this.isEditVariant = true
      this.variantOptionLabelMaps = data
      this.handleVariantsForPreview()
    },
    /**
     * handler for getting drop down icon class
     * TODO add types for data
     */
    dropdownIcon(active: boolean): string {
      return active ? 'menu-up' : 'menu-down'
    },
    /**
     * Navigate to add variant page
     */
    handleAddVariant() {
      this.$router.push(`/products/${this.productId}/variant`)
    },
    /**
     * Add an option on Add another option button click
     */
    addOption() {
      this.variantOptionLabelMaps.push({
        id: generateKSUID(),
        index: this.variantOptionLabelMaps.length,
        optionLabel: '',
        optionValues: [],
      } as VariantOptionLabelMapper)
    },
    /**
     * Remove an option on remove button click
     * @param indexToRemove - index of the option to be removed
     */
    removeOption(indexToRemove: number) {
      this.variantOptionLabelMaps.splice(indexToRemove, 1)
      this.variantOptionLabelMaps = this.variantOptionLabelMaps.map(
        (option: VariantOptionLabelMapper, index: number) => {
          return { ...option, ...{ id: generateKSUID(), index: index } }
        }
      )
      this.handleVariantsForPreview()
    },
    /**
     * Handles the change in any of the options present
     * @param changedOption - an object of VariantOptionLabelMapper
     */
    handleOptionChange(changedOption: VariantOptionLabelMapper) {
      this.variantOptionLabelMaps[changedOption.index] = changedOption
      this.handleVariantsForPreview()
    },
    /**
     * Handles the variants shown for preview
     * It generates different possible combinations of selected options and
     * adds, modifies and removes the variants shown for preview
     */
    handleVariantsForPreview() {
      let variantToCombinate = {}
      this.selectedOptionLabels = []
      // get option values for MAX_OPTIONS in a object for combinations
      // variantToCombinate should have something like {option1:['s'], option2:['red'], option3:['cotton']}
      this.variantOptionLabelMaps.forEach((option, index) => {
        // variantToCombinate[`option${index + 1}`] = option.optionValues
        if (option.optionValues.length > 0) {
          this.selectedOptionLabels.push(option.optionLabel)
          variantToCombinate = {
            ...variantToCombinate,
            ...{ [`option${index + 1}`]: option.optionValues },
          }
        }
      })
      //generate all combinations for the available option values
      let generatedOptions: any = getCombinations(variantToCombinate)
      //generated options - example - [{option1:'s',option2:'red', option3:'cotton'}]
      //generated options will have the length equal to (option1.values.length)*(option2.values.length)*(option3.values.length)
      //TODO make sure the generated options length is 0 when no options are passed
      if (!generatedOptions[0].option1) {
        generatedOptions = []
      }
      if (generatedOptions.length >= this.variantsForPreview.length) {
        // if generated option length is more than variantsForPreview length then
        // either new variants has to be added or existing variant needs to modified
        //if there are variants present already modify it based on generated options
        if (!this.isReorderVariant && !this.isDeleteVariant) {
          this.variantsForPreview = this.variantsForPreview.map(
            (variantInput: VariantInputWithAudit, index: number) => {
              return {
                ...variantInput,
                variant: {
                  ...variantInput.variant,
                  option1: generatedOptions[index].option1,
                  option2: generatedOptions[index].option2,
                  option3: generatedOptions[index].option3,
                  title: generateVariantTitle(
                    generatedOptions[index].option1,
                    generatedOptions[index].option2,
                    generatedOptions[index].option3
                  ),
                  index: index,
                },
              }
            }
          )
        } else {
          try {
            this.variantsForPreview = getTempVariantsForPreview(
              generatedOptions,
              this.variantsForPreview,
              this.inEditMode,
              this.productId,
              this.selectedOptionLabels
            )
            this.isReorderVariant = false
            this.isDeleteVariant = false
            this.isEditVariant = false
          } catch (err) {
            this.$buefy.toast.open({
              message: 'Something went Wrong. Please try again later.',
              position: 'is-bottom',
              type: 'is-danger',
            })
          }
        }
        //if there are remaining options in generated options create new variants
        if (!this.inEditMode) {
          for (
            let index = this.variantsForPreview.length;
            index < generatedOptions.length;
            index++
          ) {
            this.variantsForPreview.push({
              variant: {
                title: generateVariantTitle(
                  generatedOptions[index].option1,
                  generatedOptions[index].option2,
                  generatedOptions[index].option3
                ),
                option1: generatedOptions[index].option1,
                option2: generatedOptions[index].option2,
                option3: generatedOptions[index].option3,
                chargeTax: this.chargeTax,
                disableAutomaticDiscount: this.disableAutomaticDiscount,
                isOversellable: this.isOversellable,
                price: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                upsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedUpsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalUpsellPrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                packagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                discountedPackagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                internalPackagePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                variantType: this.variantType,
                sku: this.sku,
                imageUrl: '',
                comparePrice: {
                  amount: 0,
                  currency: CurrencyEnum.Usd,
                },
                trackQuantity: this.trackQuantity,
                quantity: this.quantity,
                index: index,
              },
              isDeleted: false,
            })
          }
        }
      } else {
        try {
          this.variantsForPreview = getTempVariantsForPreview(
            generatedOptions,
            this.variantsForPreview,
            this.inEditMode,
            this.productId,
            this.selectedOptionLabels
          )
          this.isDeleteVariant = false
        } catch (err) {
          this.$buefy.toast.open({
            message: 'Something went Wrong. Please try again later.',
            position: 'is-bottom',
            type: 'is-danger',
          })
        }
      }
      this.$emit('set-variants', this.variantsForPreview)
      this.$emit('set-option-labels', this.selectedOptionLabels)
    },
    /**
     * Handles if a variant is edited in variant preview component
     * @param editedVariantInput - VariantInputWithAudit
     */
    editVariant(editedVariantInput: VariantInputWithAudit) {
      // find the edited variant and modify it
      this.variantsForPreview = this.variantsForPreview.map(
        (variantInput: VariantInputWithAudit) => {
          const { variant } = variantInput
          if (variant.title === editedVariantInput.variant.title) {
            return editedVariantInput
          }
          return variantInput
        }
      )
      this.$emit('set-variants', this.variantsForPreview)
    },
    /**
     * Populate variant input if variant is present already
     */
    populateVariantInput() {
      this.variants?.forEach((variant: VariantInput) => {
        this.variantsForPreview.push({
          variant: variant,
          isDeleted: false,
        })
      })
      this.optionLabels.forEach((optionLabel: string) => {
        this.selectedOptionLabels.push(optionLabel)
      })
      this.selectedOptionLabels.forEach(
        (optionLabel: string, index: number) => {
          this.variantOptionLabelMaps.push({
            id: generateKSUID(),
            index: index,
            optionLabel: optionLabel,
            optionValues: [],
          })
          this.variantsForPreview.forEach(
            (variantInput: VariantInputWithAudit) => {
              const { variant } = variantInput
              switch (index) {
                case 0:
                  if (
                    !this.variantOptionLabelMaps[index].optionValues.includes(
                      variant.option1
                    )
                  ) {
                    this.variantOptionLabelMaps[index].optionValues.push(
                      variant?.option1
                    )
                  }
                  break
                case 1:
                  if (
                    !this.variantOptionLabelMaps[index].optionValues.includes(
                      variant?.option2 || ''
                    )
                  ) {
                    this.variantOptionLabelMaps[index].optionValues.push(
                      variant?.option2 || ''
                    )
                  }
                  break
                case 2:
                  if (
                    !this.variantOptionLabelMaps[index].optionValues.includes(
                      variant?.option3 || ''
                    )
                  ) {
                    this.variantOptionLabelMaps[index].optionValues.push(
                      variant?.option3 || ''
                    )
                  }
              }
            }
          )
        }
      )
    },
    /**
     * Show the variant delete confirm popup
     *
     * @param deleteVariantInput - array of variants to be deleted
     */
    confirmVariantDelete(deleteVariantInput: VariantInputWithAudit[]) {
      const { variant } = deleteVariantInput[0]
      const title = deleteVariantInput.length === 1 ? variant.title : 'Variants'
      const message =
        `Are you sure you want to delete the ` +
        (deleteVariantInput.length === 1
          ? ` variant <strong>${title}</strong>?`
          : deleteVariantInput.length === this.variantsForPreview.length
          ? ' Variants. This will delete the product.'
          : 'variants.')
      this.$buefy.dialog.confirm({
        title: `Deleting ${title}?`,
        message: `${message} This action cannot be reversed.`,
        confirmText: 'Delete',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await Promise.all(
              deleteVariantInput.map((variant: VariantInputWithAudit) => {
                variant.isDeleted = true
                return deleteVariant(
                  this.productId,
                  variant.variant?.variantId || ''
                )
              })
            )
            this.$buefy.toast.open({
              message:
                pluralize('Variant', deleteVariantInput.length, false) +
                ' deleted Successfully!',
              position: 'is-bottom',
              type: 'is-success',
            })
            deleteVariantInput.map((variant: VariantInputWithAudit) => {
              this.variantsForPreview = this.variantsForPreview.filter(
                (variantInput: VariantInputWithAudit) => {
                  return variantInput.variant.title !== variant.variant.title
                }
              )
            })
            this.$emit('set-variants', this.variantsForPreview)
          } catch (e) {
            this.$buefy.toast.open({
              message: 'Something went Wrong. Please try again later.',
              position: 'is-bottom',
              type: 'is-danger',
            })
          }
          if (this.variantsForPreview.length === 0) {
            this.$router.push('/products')
          }
        },
      })
    },
    /**
     * Show the reorder variants popup
     */
    reOrderVariants() {
      this.$buefy.modal.open({
        parent: this,
        component: ReorderVariants,
        hasModalCard: true,
        trapFocus: true,
        props: {
          defaultOptions: this.variantOptionLabelMaps,
        },
        events: {
          'update-options': this.handleReorderVariants,
        },
      })
    },
  },
})
