


































































import Vue, { PropType } from 'vue'
import {
  ProductInput,
  Product,
  Variant,
  CurrencyEnum,
  VariantTypeEnum,
} from '../../../../shared/types/types'
import { createProduct } from '../../lib/product'
import { getDefaultVariant } from '../../lib/utils'
import { DEFAULT_OPTION_LABEL } from '../../constants/'
import Separator from '../shared/Separator.vue'
import Message from '../shared/Message.vue'
import { MessageTypeEnum } from '@/types'
import { errorMessage } from '@/constants/message'

export default Vue.extend({
  name: 'DuplicateProduct',
  components: { Separator, Message },
  props: {
    product: {
      type: Object as PropType<Product>,
      default: {},
    },
  },
  data() {
    return {
      isSaving: false,
      radio: false,
      duplicateProduct: {} as ProductInput,
      image: '',
      skus: '',
      variants: false,
      quantity: '',
      errorMessage: {},
    }
  },
  created() {
    /**
     * Method to duplicate the product
     */
    this.duplicateProduct = {
      // adding copy of as prefix to the title
      title: 'Copy of ' + this.product.title,
      description: this.product.description,
      imageUrl:
        this.image && this.product.imageUrl ? this.product.imageUrl : '',
      variants: [],
      optionLabels:
        this.variants && this.product.optionLabels
          ? this.product.optionLabels
          : [DEFAULT_OPTION_LABEL],
    }
  },
  methods: {
    /**
     * Handles duplicate product submit event
     */
    async handleDuplicateProduct() {
      //TODO show validation error message if the title is empty
      if (this.duplicateProduct.title.trim() !== '') {
        this.duplicateProduct = {
          ...this.duplicateProduct,
          imageUrl:
            this.image && this.product.imageUrl ? this.product.imageUrl : '',
          optionLabels:
            this.variants && this.product.optionLabels
              ? this.product.optionLabels
              : [DEFAULT_OPTION_LABEL],
        }
        if (
          this.variants &&
          this.product.variants &&
          this.product.variants.length > 0
        ) {
          // if variants check box is selected and product variants length > 0
          // push the variants for duplicating the product
          this.product.variants.forEach((variant: Variant, index: number) => {
            this.duplicateProduct.variants.push({
              title: variant?.title || '',
              option1: variant?.option1 || '',
              option2: variant?.option2 || undefined,
              option3: variant?.option3 || undefined,
              chargeTax: variant?.chargeTax || false,
              disableAutomaticDiscount:
                variant?.disableAutomaticDiscount || false,
              isOversellable: variant?.isOversellable || false,
              price: {
                amount: variant?.price?.amount || 0,
                currency: variant?.price?.currency || CurrencyEnum.Usd,
              },
              trackQuantity: variant?.trackQuantity || false,
              quantity: variant?.quantity || 0,
              sku: variant?.sku || '',
              variantType: variant?.variantType || VariantTypeEnum.Physical,
              index: index,
            })
          })
        } else {
          // if variants check box is unselected create a default variant
          // as atleast one variant is required for creating a default variant
          this.duplicateProduct.optionLabels = []
          this.duplicateProduct.optionLabels.push('Title')
          this.duplicateProduct.variants.push(
            getDefaultVariant(this.duplicateProduct.title)
          )
        }
        try {
          this.isSaving = true
          const createdProduct = await createProduct(this.duplicateProduct)
          if (createdProduct?.productId) {
            this.$emit('productCreated', createdProduct.productId)
          }
          this.isSaving = false
        } catch (e) {
          this.isSaving = false
          this.$buefy.toast.open({
            message: 'Something went Wrong. Please try again later.',
            position: 'is-bottom',
            type: 'is-danger',
          })
        }
        this.$emit('close')
      } else {
        this.errorMessage = {
          type: MessageTypeEnum.Error,
          messageList: [errorMessage.TITLE_EMPTY],
          message: `There is error with this Duplicate variant:`,
        }
      }
    },
  },
})
